import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './static/scss/animations.scss';
import './static/scss/framework.scss';
import './static/scss/global_variables.scss';
import '../node_modules/v-tooltip/dist/v-tooltip.css';
import './static/scss/styles.scss';
import './fa';

import VTooltipPlugin from 'v-tooltip';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from './App.vue';
import router from './router';

const gtagAppId = process.env.VUE_APP_GTAG_APP_ID as string;

createApp(App)
  .use(router)
  .use(VTooltipPlugin)
  .use(VueGtag, {
    config: { id: gtagAppId },
    pageTrackerScreenviewEnabled: true,
    router,
  })
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
