import axios, { AxiosInstance } from 'axios';

import { PlaybackInfo } from '../../../shared/models/common-models';

export class ApiService {
  private _instance: AxiosInstance;

  constructor() {
    this._instance = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
    });
  }

  getPlaybackInfo(sessionId: string): Promise<PlaybackInfo> {
    return this._instance
      .get<PlaybackInfo>('playback-info', { params: { sessionId } })
      .then((r) => r.data as PlaybackInfo);
  }

  generateId(): Promise<{ id: string }> {
    return this._instance.get<{ id: string }>('id').then((r) => r.data);
  }

  getVtt(file: File): Promise<File> {
    const formData = new FormData();
    formData.append('file', file);

    return this._instance
      .post('get-vtt', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob',
      })
      .then(
        // Create the file but replace the ending with '.vtt'
        (r) => new File([r.data as Blob], file.name.replace(/\..+$/g, '.vtt'))
      );
  }
}

export default new ApiService();
