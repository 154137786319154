export class AppService {
  private _sessionId!: string;

  get qrURL(): string {
    return `${document.location.protocol}//${document.location.host}/remote/${this._sessionId}`;
  }

  setSessionId(sessionId: string): void {
    this._sessionId = sessionId;
  }
}

export default new AppService();

/* eslint-disable */
// Add print environment method for debugging
(window as any)['printEnv'] = () => {
  console.log(process.env);
};
