import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import apiService from '@/services/api.service';
import AppService from '@/services/app.service';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: async (to, from, next) => {
      const sessionId = await apiService.generateId().then((r) => r.id);
      to.meta['sessionId'] = sessionId;
      AppService.setSessionId(sessionId);
      next();
    },
  },
  {
    path: '/remote/:sessionId',
    name: 'Remote',
    component: () =>
      import(/* webpackChunkName: "remote" */ '../views/Remote.vue'),
    beforeEnter: async (to, from, next) => {
      const sessionId = to.params['sessionId'] as string;
      to.meta['sessionId'] = sessionId;

      try {
        const playbackInfo = await apiService.getPlaybackInfo(sessionId);
        to.meta['playbackInfo'] = playbackInfo;
        AppService.setSessionId(sessionId);
      } catch (error) {
        console.error(`Failed to fetch session id playback info: `, error);
      }

      next();
    },
  },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue'),
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
