import { library } from '@fortawesome/fontawesome-svg-core';
import { faChromecast } from '@fortawesome/free-brands-svg-icons/faChromecast';
import { faBackward } from '@fortawesome/free-solid-svg-icons/faBackward';
import { faCaretSquareDown } from '@fortawesome/free-solid-svg-icons/faCaretSquareDown';
import { faCaretSquareUp } from '@fortawesome/free-solid-svg-icons/faCaretSquareUp';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { faClosedCaptioning } from '@fortawesome/free-solid-svg-icons/faClosedCaptioning';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faFilm } from '@fortawesome/free-solid-svg-icons/faFilm';
import { faForward } from '@fortawesome/free-solid-svg-icons/faForward';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMobile } from '@fortawesome/free-solid-svg-icons/faMobile';
import { faPause } from '@fortawesome/free-solid-svg-icons/faPause';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faVolumeDown } from '@fortawesome/free-solid-svg-icons/faVolumeDown';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons/faVolumeUp';
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons/faWindowRestore';

library.add(
  faFilm,
  faClosedCaptioning,
  faChromecast,
  faMobile,
  faPlay,
  faForward,
  faBackward,
  faPlus,
  faMinus,
  faPause,
  faCaretSquareUp,
  faCaretSquareDown,
  faInfoCircle,
  faTimes,
  faUndo,
  faCircleNotch,
  faVideo,
  faExclamationTriangle,
  faWindowRestore,
  faVolumeUp,
  faVolumeDown
);
